<template>
  <section>
    <div class="row">
      <div class="col-md-10">
        <h2 class="titulo-links-escolha">Foto de Perfil</h2>
      </div>
      <div class="col-md-2">
        <button class="button-snap p-button p-button-danger p-component p-button-sm btn-color" @click="voltar()"
          style="margin-top: 35px;float: right;">
          Voltar
        </button>
      </div>
    </div>
    <div class="pm-card">
      <div class="field col-12 md:col-12" v-if="this.lista">
        <pm-TabView>
          <pm-TabPanel header="Foto do Aluno">
            <div class="field col-12 md:col-12" >
              <div class="field col-12 md:col-3" v-if="this.lista.foto">
                 <img :src="pathDoSite+'storage/'+this.lista.foto" style="width:100%;height:auto;">
                 <div style="margin-top: 15px;">
                    <button class="button-snap p-button p-button-danger p-component p-button-sm btn-color" @click="Remover">
                      Remover Foto
                    </button>
                 </div>
              </div>
              <div class="field col-12 md:col-3" v-else>
                O aluno ainda não possui uma foto.
              </div>
            </div>
          </pm-TabPanel>
          <pm-TabPanel header="Area de Captura da Foto" >
            <div class="field col-12 md:col-12" v-if="!this.lista.foto">
              <Camera @perfil="perfil()" v-if="showCamera" :id="this.id" />
            </div>
            <div class="field col-12 md:col-12" v-else>
              Este aluno já possui Foto!
            </div>
          </pm-TabPanel>
        </pm-TabView>
      </div>
    </div>
  </section>
</template>
<script>

import Camera from '@/components/Camera.vue'
import { defineComponent } from "vue";
import axios from 'axios';
import Util from "@/utils/rota";

export default defineComponent({
  props: {
    id: {},
    pg: {},
  },
  components: {
    Camera
  },
  data () {
    return {
      showCamera: true,
      lista:[],
      pathDoSite: axios.defaults.baseURL.replace('api/v1',''),
      funcao:0,
    }
  },
  async beforeMount() {
    await this.logado();
    if (this.id == null) {
      if (this.funcao === 1 || this.funcao === 2 || this.funcao === 7) {
        this.$router.push({ name: "alunosAll", params: { pg: this.pg } });
      } else {
        this.$router.push({
          name: "coordenacao-listaalunos",
          params: { pg: this.pg },
        });
      }
    }
    else {
      this.perfil()
    }
  },
  methods:{
    async perfil(){
      let data = await axios.get('fotos/perfil/'+this.id+'/tipo/1');
      this.lista = data.data;
    },
    async logado() {
      const token = sessionStorage.getItem("token");
      const util = new Util();
      const data = await util.WhoIam(token);
      this.funcao = data.funcao;
    },
    async Remover(){
      data = axios.delete('fotos/perfil/'+this.id+'/tipo/1');

      this.perfil()
    },
    voltar(){
      if (this.funcao === 1 || this.funcao === 2 || this.funcao === 7) {
        this.$router.push({ name: "alunosAll", params: { pg: this.pg } });
      } else {
        this.$router.push({
          name: "coordenacao-listaalunos",
          params: { pg: this.pg },
        });
      }
    }
  },
})
</script>


